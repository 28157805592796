import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"

const Content = ({ bonuses, locale }) => {
  return (
    <div>
      <MainSearch locale={locale} bonuses={bonuses} />
    </div>
  )
}

const MobileFriendly = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)
  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => x.isMobileFriendly)

  const pageData = data.sanityGenericLandingPage
  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
           bonuses={bonuses} alternateSlug={alternateLocaleSlugs}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(MobileFriendly)
export const pageQuery = graphql`
  query MobileFriendlyLanding {
    sanityGenericLandingPage(
      name: { eq: "Mobile Friendly" }
    ) {
      ...GenericLanding
    }
  }
`
